import React, { useContext, useEffect, useRef, useState } from 'react';
import { Loader, PageHeaderMui, TagModal, snackbarService } from 'components';
import { getLocations, bulkUpdateLocations, getMarkets, getPhoneNumbers } from '../../shared/common.api';
import { Box, Button, IconButton, Tooltip, Typography, Stack, Paper, CircularProgress, Table, TableHead, TableBody, TableRow, TableCell, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Divider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { UserStateContext } from 'context/user-state-context';
import { Icon } from '../../components/icon/icon.component';
import { history } from '../../root.component';
import { AddTagsIcon, ArrowForwardIcon, CloseIcon } from 'components/mui';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { DataGrid, GridCellModes, gridClasses } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { isValidPhoneNumber } from 'libphonenumber-js';
import isEmail from 'validator/lib/isEmail';
import validator from 'validator';
const hiddenFields = ['deleted_when', 'created_when', 'updated_when', 'company', 'account', 'transactions_last_created', 'transactions_furthest_out', 'lat', 'lon', 'account_id', 'needs_geocode', 'friendly_name', 'transactions_last_7_days'];
const readOnlyFields = ['id', 'entity_ref', 'entity_source'];
const StyledBox = styled('div')(({
  theme
}) => ({
  width: '100%',
  height: '100%',
  '& .MuiDataGrid-cell--editing': {
    backgroundColor: 'rgb(255,215,115, 0.19)',
    color: '#1a3e72',
    '& .MuiInputBase-root': {
      height: '100%'
    }
  },
  '& .Mui-error': {
    backgroundColor: 'rgb(126,10,15, 0.1)',
    color: theme.palette.error.main,
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgb(126,10,15, 0)'
    })
  },
  [`.${gridClasses.cell}.cellerror`]: {
    backgroundColor: 'rgb(126,10,15, 0.1)',
    color: theme.palette.error.main,
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgb(126,10,15, 0)'
    })
  }
}));
export const BulkEditLocations = () => {
  const {
    asCompany,
    hasPermission,
    user
  } = useContext(UserStateContext);
  const [locations, setLocations] = React.useState([]);
  const [originalLocations, setOriginalLocations] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [allHeaders, setAllHeaders] = React.useState([]);
  const componentRef = useRef();
  const [resizeBreakpoint, setResizeBreakpoint] = useState(1000);
  const [hasMadeChanges, setHasMadeChanges] = useState(false);
  const [previewModal, setPreviewModal] = useState(null);
  const [errorsList, setErrorsList] = useState([]);
  React.useEffect(() => {
    const resizeListener = () => {
      if (componentRef?.current?.offsetHeight) {
        setResizeBreakpoint(componentRef.current.offsetHeight);
      }
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);
  const calcMaxWidth = () => {
    if (1600 * 100 / window.innerWidth > 95) {
      return '95vw';
    }
    return '1000px';
  };
  const getType = key => {
    if (key == 'lat' || key == 'lon') {
      return 'number';
    } else if (key == 'closed_when' || key == 'transactions_last_created' || key == 'transactions_furthest_out') {
      return 'dateTime';
    } else if (key == 'deleted_when') {
      return 'date';
    } else if (key == 'needs_geocode' || key == 'is_active' || key == 'is_deleted') {
      return 'boolean';
    } else if (key == 'timezone' || key == 'market' || key == 'sms_phone_number') {
      return 'singleSelect';
    } else {
      return 'string';
    }
  };
  const getValueOptions = (key, dynamicData) => {
    if (key == 'market') {
      return dynamicData.markets.map(market => {
        return {
          value: market.id,
          label: market.name
        };
      });
    }
    if (key == 'timezone') {
      return [{
        value: 'America/New_York',
        label: 'Eastern Standard Time (EST)'
      }, {
        value: 'America/Chicago',
        label: 'Central Standard Time (CST)'
      }, {
        value: 'America/Denver',
        label: 'Mountain Standard Time (MST)'
      }, {
        value: 'America/Los_Angeles',
        label: 'Pacific Standard Time (PST)'
      }, {
        value: 'America/Anchorage',
        label: 'Alaska Standard Time (AKST)'
      }, {
        value: 'Pacific/Honolulu',
        label: 'Hawaii Standard Time (HST)'
      }, {
        value: 'Etc/GMT+4',
        label: 'Atlantic Standard Time (AST)'
      }, {
        value: 'Etc/GMT+7',
        label: 'Arizona'
      }];
    }
    if (key == 'sms_phone_number') {
      return dynamicData.phoneNumbers.map(phone => {
        return {
          value: phone.id,
          label: phone.phone
        };
      });
    }
    return null;
  };
  const getAlignment = key => {
    if (key == 'needs_geocode' || key == 'is_active' || key == 'is_deleted') {
      return 'center';
    }
    return 'left';
  };
  const unwrapData = (data, entityDataHeaders) => {
    return data.map(item => {
      const entityData = item?.entity_data;
      const entityDataUnwrapped = {};
      if (entityData && entityData != null && entityData != undefined && entityData != '' && Object.keys(entityData)?.length) {
        Object.keys(entityData).forEach(key => {
          entityDataUnwrapped['entity_data.' + key] = entityData[key];
        });
      } else {
        entityDataHeaders.map(header => {
          entityDataUnwrapped[header.field] = '';
        });
      }
      return {
        ...item,
        ...entityDataUnwrapped
      };
    });
  };
  useEffect(() => {
    setLoading(true);
    const promises = [getLocations({
      company: asCompany.id,
      limit: 1000
    }), getMarkets({
      company: asCompany.id,
      limit: 1000
    }), getPhoneNumbers({
      company: asCompany.id,
      limit: 1000
    })];
    Promise.all(promises).then(([locData, MarketData, phoneData]) => {
      const dynamicData = {
        markets: MarketData.data.results,
        phoneNumbers: phoneData.data.results
      };
      const headers = [{
        field: 'name',
        headerName: 'Name',
        width: 175,
        editable: true,
        align: 'left'
      }];
      const entityDataHeaders = [];
      locData.data.results?.map(location => {
        //get every key
        for (const [key, value] of Object.entries(location)) {
          if (key == 'entity_data') {
            if (value != null && value != undefined && value != '' && Object.keys(value)?.length) {
              Object.keys(value).map(subKey => {
                if (!entityDataHeaders.some(header => header.field === 'entity_data.' + subKey)) {
                  entityDataHeaders.push({
                    field: 'entity_data.' + subKey,
                    headerName: 'entity_data.' + subKey,
                    width: 175,
                    editable: true,
                    align: getAlignment(subKey),
                    type: getType(subKey),
                    valueOptions: getValueOptions(subKey, dynamicData)
                  });
                }
              });
            }
          } else if (!headers.some(header => header.field === key)) {
            if (!hiddenFields.includes(key)) {
              headers.push({
                field: key,
                headerName: key,
                width: 175,
                editable: !readOnlyFields.includes(key),
                align: getAlignment(key),
                type: getType(key),
                valueOptions: getValueOptions(key, dynamicData)
              });
            }
          }
        }
      });
      headers.push(...entityDataHeaders);
      const unwrapped = unwrapData(locData.data.results, entityDataHeaders);
      setLocations(unwrapped);
      setOriginalLocations(JSON.parse(JSON.stringify(unwrapped)));
      setAllHeaders(headers);
      setLoading(false);
      setResizeBreakpoint(componentRef.current.offsetHeight);
    });
  }, []);
  const rewrapData = data => {
    return data.map(item => {
      const entityData = {};
      Object.keys(item).forEach(key => {
        if (key.startsWith('entity_data.')) {
          entityData[key.split('entity_data.')[1]] = item[key];
        }
      });
      //if every key in entity_data is empty, set entity_data to null
      let entityDataIsEmpty = true;
      Object.keys(entityData).forEach(key => {
        if (entityData[key] != null && entityData[key] != undefined && entityData[key] != '') {
          entityDataIsEmpty = false;
        }
      });
      return {
        ...item,
        company_id: item.company,
        entity_data: entityDataIsEmpty ? null : entityData
      };
    });
  };
  const handleBulkSave = () => {
    bulkUpdateLocations(rewrapData(locations)).then(() => {
      snackbarService.popup({
        type: 'success',
        message: 'Locations updated successfully'
      });
      setPreviewModal(null);
      history.push('/locations');
    }).catch(() => {
      snackbarService.popup({
        type: 'error',
        message: 'Unknown error trying to update locations'
      });
    });
  };
  const preparePreviewModal = () => {
    const diff = [];
    locations.map((location, index) => {
      const originalLocation = originalLocations.find(item => item.id === location.id);
      for (const [key, value] of Object.entries(location)) {
        if (key === 'entity_data') {
          continue;
        }
        if (value != originalLocation[key]) {
          diff.push({
            row: index + 1,
            field: key,
            change: location[key] || 'Empty',
            original: originalLocation[key] || 'Empty'
          });
        }
      }
    });
    setPreviewModal(diff);
  };
  const validateField = e => {
    if (e.field === 'name' && e.value.length < 1) {
      return false;
    }
    if (e.field === 'phone' && e.value != '' && !isValidPhoneNumber(e.value)) {
      return false;
    }
    if (e.field === 'email_from_address' && e.value != '' && !isEmail(e.value)) {
      return false;
    }
    if ((e.field === 'market' || e.field === 'sms_phone_number') && e.value != '' && !validator.isUUID(e.value)) {
      return false;
    }
    return true;
  };
  return <>
      <CssBaseline />

      <div className="wrapper" ref={componentRef}>
        <PageHeaderMui type={'Bulk Edit Locations'} icon={<Icon name="custom-location_on" size={34} />} />

        <div className="mui-wrapper">
          <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center'
        }}>
            <Stack sx={{
            p: '20px',
            width: '100%',
            height: '100%'
          }} spacing={'20px'}>
              <Paper sx={{
              borderRadius: '16px',
              width: '100%',
              height: '100%'
            }}>
                {loading ? <Box sx={{
                width: '100%',
                height: '100%'
              }}>
                    <Box sx={{
                  py: '200px'
                }}>
                      <Box sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    justifyItems: 'center',
                    alignItems: 'center'
                  }}>
                        <CircularProgress />
                      </Box>
                    </Box>
                  </Box> : <div style={{
                height: resizeBreakpoint - 170,
                width: '100%'
              }}>
                    <StyledBox>
                      <DataGridPro disableMultipleSelection hideFooterSelectedRowCount rows={locations} columns={allHeaders} getCellClassName={params => {
                    if (errorsList.some(error => error.id === params.id && error.field === params.field)) {
                      return 'cellerror';
                    }
                    return '';
                  }} onCellEditCommit={e => {
                    if (!validateField(e)) {
                      setErrorsList(prev => {
                        const index = prev.findIndex(item => item.id === e.id && item.field === e.field);
                        if (index === -1) {
                          prev.push({
                            id: e.id,
                            field: e.field,
                            row: locations.findIndex(item => item.id === e.id) + 1
                          });
                        }
                        return [...prev];
                      });
                    } else {
                      setErrorsList(prev => {
                        const index = prev.findIndex(item => item.id === e.id && item.field === e.field);
                        if (index !== -1) {
                          prev.splice(index, 1);
                        }
                        return [...prev];
                      });
                    }
                    setLocations(prev => {
                      const index = prev.findIndex(item => item.id === e.id);
                      prev[index][e.field] = e.value;
                      return [...prev];
                    });
                    setHasMadeChanges(true);
                  }} />
                    </StyledBox>
                  </div>}
              </Paper>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box />
                <Stack direction="row" spacing={2}>
                  <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                    <Typography variant="body1" sx={{
                    color: '#1A3E72'
                  }}>
                      {errorsList.length ? `Please correct the errors before previewing on row ${errorsList[0]?.row}, ${errorsList[0]?.field}` : ''}
                    </Typography>
                  </Box>
                  <Button sx={{
                  backgroundColor: '#F3F3F4',
                  '&:hover': {
                    backgroundColor: '#F3F3F4'
                  }
                }} onClick={e => {
                  history.push('/locations');
                }} variant="contained">
                    <Box sx={{
                    py: '5px',
                    px: '12px'
                  }}>
                      <Typography sx={{
                      color: '#3898D9'
                    }}>Cancel </Typography>
                    </Box>
                  </Button>
                  <Button disabled={!hasMadeChanges || errorsList.length} onClick={e => {
                  preparePreviewModal();
                }} sx={{
                  width: '170px',
                  backgroundColor: '#3898D9',
                  '&:hover': {
                    backgroundColor: '#3898D9'
                  }
                }} variant="contained">
                    Preview Changes
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </div>
      </div>

      <Dialog scroll="paper" PaperProps={{
      style: {
        borderRadius: '28px',
        maxHeight: '80vh',
        minHeight: '55vh',
        maxWidth: calcMaxWidth(),
        minWidth: calcMaxWidth()
      }
    }} open={!!previewModal} onClose={() => {
      setPreviewModal(null);
    }}>
        <DialogTitle sx={{
        m: 0,
        p: 2,
        fontSize: 'medium'
      }}>
          <Stack spacing="8px" sx={{
          p: '4px'
        }}>
            <Stack spacing={'8px'}>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                  <Typography sx={{
                  fontWeight: '500',
                  lineHeight: '30px',
                  fontSize: '24px',
                  display: 'flex',
                  alignItems: 'center'
                }} variant="h4" component="span">
                    Preview Changes
                  </Typography>
                </Box>
                <IconButton onClick={() => {
                setPreviewModal(null);
              }}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Stack>
            <Divider />
          </Stack>
        </DialogTitle>

        <DialogContent sx={{
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
          width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
          marginTop: '20px',
          marginBottom: '20px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }}>
          <Stack sx={{
          pt: 1,
          flexGrow: 1
        }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography sx={{
                    fontWeight: '600'
                  }}>Row/Column </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={{
                    fontWeight: '600'
                  }}>Change </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {previewModal?.map((location, index) => {
                return <TableRow key={index}>
                      <TableCell>
                        <Typography variant="body1" sx={{
                      fontWeight: '500'
                    }}>
                          Row {location.row} / {location.field}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" sx={{
                      fontWeight: '500'
                    }}>
                          {location.original} <ArrowForwardIcon sx={{
                        color: '#3898D9'
                      }} /> {location.change}
                        </Typography>
                      </TableCell>
                    </TableRow>;
              })}
              </TableBody>
            </Table>
          </Stack>
        </DialogContent>

        <DialogActions sx={{
        p: '20px'
      }}>
          <Stack spacing="16px" direction={'row'}>
            <Button sx={{
            backgroundColor: '#F3F3F4',
            '&:hover': {
              backgroundColor: '#F3F3F4'
            }
          }} onClick={e => {
            setPreviewModal(null);
          }} variant="contained">
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#3898D9'
              }}>Cancel </Typography>
              </Box>
            </Button>
            <Button onClick={e => {
            handleBulkSave();
          }} variant="contained" color="primary" sx={{
            backgroundColor: '#3898D9',
            '&:hover': {
              backgroundColor: '#3898D9'
            }
          }}>
              <Box sx={{
              py: '5px',
              px: '12px'
            }}>
                <Typography sx={{
                color: '#fff'
              }}> Save Changes</Typography>
              </Box>
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>;
};