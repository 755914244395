import React, { useContext, useEffect, useRef, useState } from 'react';
import { PageHeaderMui, snackbarService } from 'components';
import { getLocations, importLocationsCSV } from '../../shared/common.api';
import { Box, Button, IconButton, Tooltip, Typography, Stack, Paper, CircularProgress, Table, TableHead, TableBody, TableRow, TableCell, TextField } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { UserStateContext } from 'context/user-state-context';
import { Icon } from '../../components/icon/icon.component';
import { history } from '../../root.component';
import { Dashboard } from '@uppy/react';
import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { createMultipartUpload, listParts, prepareUploadPart, completeMultipartUpload, abortMultipartUpload } from 'components/file-viewer/provider';
import Papa from 'papaparse';
const ignoreChangesList = ['updated_when', 'entity_ref', 'entity_source', 'created_when', 'deleted_when', 'lat', 'lon', 'company_id', 'company', 'needs_geocode', 'friendly_name', 'transactions_furthest_out', 'transactions_last_created', 'transactions_last_7_days', 'sms_phone_number_id', 'market_id'];
const fullIgnoreList = ['updated_when'];
export const ImportLocations = () => {
  const {
    asCompany,
    hasPermission,
    user
  } = useContext(UserStateContext);
  const [uppy, setUppy] = useState();
  const [loading, setLoading] = React.useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [pendingChanges, setPendingChanges] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [preventUpload, setPreventUpload] = useState(false);
  let hasError = false;
  useEffect(() => {
    setLoading(true);
    getLocations({
      company: asCompany.id,
      limit: 999
    }).then(response => {
      const uppy = new Uppy({
        autoProceed: true,
        restrictions: {
          maxNumberOfFiles: 1,
          minNumberOfFiles: 1,
          maxTotalFileSize: 25000000,
          // Max file size 25mb
          allowedFileTypes: ['.csv']
        },
        disableThumbnailGenerator: true,
        showRemoveButtonAfterComplete: false,
        fileManagerSelectionType: 'files',
        allowMultipleUploads: false,
        onBeforeFileAdded: file => {
          const name = Date.now() + '_' + file.name;
          Object.defineProperty(file.data, 'name', {
            writable: true,
            value: name
          });
          return {
            ...file,
            name,
            meta: {
              ...file.meta,
              name
            }
          };
        }
      }).use(AwsS3Multipart, {
        companionUrl: API_URL + `/company-files/${asCompany.id}/upload`,
        parentFolder: 'locations/',
        createMultipartUpload,
        listParts,
        prepareUploadPart,
        completeMultipartUpload,
        abortMultipartUpload
      }).on('file-added', file => {
        handleFile(file.data, response.data.results);
      }).on('file-removed', file => {
        setUploadedFile(null);
        setPendingChanges(null);
        setPreventUpload(false);
        setIsUploading(false);
      }).on('file-added', file => {
        handleFile(file.data, response.data.results);
      }).on('upload-success', file => {
        if (hasError) {
          snackbarService.popup({
            type: 'error',
            duration: 5000,
            message: `Error with CSV file. Please check the file and try again.`
          });
          return;
        }
        setUploadedFile({
          company: asCompany.id,
          filename: file.name
        });
      });
      setUppy(uppy);
      setLoading(false);
    });
  }, []);
  const handleFile = (file, locations) => {
    const fr = new FileReader();
    fr.onload = function () {
      const pendingRowChanges = [];
      const data = Papa.parse(fr.result);
      const rows = data.data.filter(r => r.join('').trim() !== '');
      const fields = rows.shift();
      if (!fields) {
        hasError = true;
        pendingRowChanges.push({
          row: 1,
          cell: 'File',
          change: 'Unable to parse any headers from the CSV file. Please check the file and try again.'
        });
      }
      if (!Math.max(0, rows.length)) {
        hasError = true;
        pendingRowChanges.push({
          row: 1,
          cell: 'File',
          change: 'CSV file does not contain any data. Please check the file and try again'
        });
      }
      const locationIDIndex = fields?.findIndex(field => field.toLowerCase() === 'id');
      if (locationIDIndex === -1) {
        hasError = true;
        pendingRowChanges.push({
          row: 1,
          cell: 'File',
          change: 'CSV file is missing the required ID header. Please check the file and try again'
        });
      }
      rows.forEach((row, index) => {
        if (!row[locationIDIndex] || row[locationIDIndex].trim() === '') {
          hasError = true;
          pendingRowChanges.push({
            row: index + 2,
            cell: 'ID',
            change: 'Missing required ID'
          });
        }
        const location = locations.find(l => l.id == row[locationIDIndex]);
        if (!location) {
          hasError = true;
          pendingRowChanges.push({
            row: index + 2,
            cell: 'ID',
            change: ' Incorrect Cinch Id'
          });
        }
      });

      //check if any rows are completely empty
      data.data.forEach((row, index) => {
        if (row.join('').trim() === '' && index !== 0 && index != data.data.length - 1) {
          hasError = true;
          pendingRowChanges.push({
            row: index + 1,
            cell: 'File',
            change: 'Row is completely empty'
          });
        }
      });
      if (hasError) {
        setPendingChanges(pendingRowChanges);
        setPreventUpload(true);
        return;
      }
      rows.forEach((row, index) => {
        const oldLocationRow = locations.find(l => l.id === row[locationIDIndex]);
        row.forEach((cell, cellIndex) => {
          const headerKey = fields[cellIndex].toLowerCase();
          if (fullIgnoreList.includes(headerKey)) {
            return;
          }
          const oldCell = oldLocationRow[headerKey];
          if (compare(oldCell, cell, headerKey, oldLocationRow)) {
            pendingRowChanges.push({
              row: index + 2,
              cell: fields[cellIndex],
              change: `${oldCell || 'Empty'}  -->  ${cell || 'Empty'}`,
              ignored: ignoreChangesList.includes(headerKey)
            });
          }
        });
      });
      setPendingChanges(pendingRowChanges);
    };
    try {
      fr.readAsText(file);
    } catch (e) {
      // For some unknown reason this throws an error but it still works *facepalm*
    }
  };
  const actuallyUpload = () => {
    setIsUploading(true);
    importLocationsCSV(uploadedFile).then(response => {
      setIsUploading(false);
      if (response.status === 200) {
        snackbarService.popup({
          type: 'success',
          message: 'Location changes successfully applied'
        });
        history.push('/locations');
      } else {
        snackbarService.popup({
          type: 'error',
          message: 'Error updating locations ' + response.data.message
        });
      }
    });
  };
  const compare = (oldValue, newValue, headerKey, oldRow) => {
    if (headerKey.startsWith('entity_data')) {
      if (headerKey === 'entity_data') {
        return false;
      }
      //get the key from the header
      const key = headerKey.split('.')[1];
      oldValue = oldRow?.entity_data && oldRow?.entity_data[key] ? oldRow.entity_data[key] : '';
      if (oldValue === null || oldValue === undefined || oldValue === '') {
        oldValue = '';
      }
      if (newValue === null || newValue === undefined || newValue === '') {
        newValue = '';
      }
      return oldValue !== newValue;
    }
    if (headerKey == 'phone') {
      if (oldValue && oldValue[0] == '+') {
        oldValue = oldValue.substring(1);
      }
      if (newValue && newValue[0] == '+') {
        newValue = newValue.substring(1);
      }
    }
    if (headerKey == 'is_active' || headerKey == 'needs_geocode') {
      if (oldValue == 'true' || oldValue == 'TRUE' || oldValue == 'True') {
        oldValue = true;
      }
      if (oldValue == 'false' || oldValue == 'FALSE' || oldValue == 'False') {
        oldValue = false;
      }
      if (newValue == 'true' || newValue == 'TRUE' || newValue == 'True') {
        newValue = true;
      }
      if (newValue == 'false' || newValue == 'FALSE' || newValue == 'False') {
        newValue = false;
      }
    }
    if (oldValue == newValue) {
      return false;
    }
    if (oldValue === null || oldValue === undefined || oldValue === '') {
      return newValue !== null && newValue !== undefined && newValue !== '';
    }
    if (newValue === null || newValue === undefined || newValue === '') {
      return oldValue !== null && oldValue !== undefined && oldValue !== '';
    }
    return true;
  };
  return <>
      <CssBaseline />

      <div className="wrapper">
        <PageHeaderMui type={'Bulk Update Locations via CSV'} icon={<Icon name="custom-location_on" size={34} />} />

        <div className="mui-wrapper">
          <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center'
        }}>
            <Stack sx={{
            p: '20px',
            width: '100%',
            height: '100%'
          }} spacing={'20px'}>
              <Paper sx={{
              borderRadius: '16px',
              width: '100%',
              height: '100%'
            }}>
                {loading ? <Box sx={{
                width: '100%',
                height: '100%'
              }}>
                    <Box sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  justifyItems: 'center',
                  alignItems: 'center'
                }}>
                      <CircularProgress />
                    </Box>
                  </Box> : <Stack spacing={2} sx={{
                p: '20px'
              }}>
                    <Box>
                      <Typography sx={{
                    fontSize: '14px'
                  }}>
                        Upload a CSV file to bulk update your locations in Cinch. Your CSV must contain a Cinch location id to ensure proper
                        mapping.
                      </Typography>
                    </Box>
                    <Box>
                      {uppy && <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    pt: '15px',
                    pb: '85px'
                  }}>
                          <Dashboard width={370} height={322} uppy={uppy} />
                        </Box>}
                    </Box>
                    {pendingChanges && <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box />
                        <Stack spacing={2} sx={{
                    display: 'flex',
                    alignContent: 'center'
                  }}>
                          <Box>The following changes will be applied to your locations. Please review the changes before proceeding.</Box>

                          {pendingChanges.length > 0 ? <Stack spacing={1}>
                              {pendingChanges.map((change, index) => <Stack key={index} direction="row" spacing={2}>
                                  <Typography>Row: {change.row}</Typography>
                                  <Typography>Cell: {change.cell}</Typography>
                                  <Stack direction="row" spacing={1}>
                                    <Typography>Change: </Typography> <Typography sx={{
                            fontWeight: '600'
                          }}> {change.change}</Typography>
                                    {change.ignored && <Typography sx={{
                            color: '#EF3C34'
                          }}>
                                        (This field cannot be updated via csv import. Any differences will be ignored)
                                      </Typography>}
                                  </Stack>
                                </Stack>)}
                            </Stack> : <Typography>No changes detected</Typography>}
                        </Stack>
                        <Box />
                      </Box>}
                    <Box sx={{
                  pt: 1
                }} display="flex" flexDirection="row" justifyContent="space-between">
                      <Box />
                      <Box>
                        <Button variant="contained" disabled={!preventUpload && (!uploadedFile || isUploading)} endIcon={isUploading && <CircularProgress sx={{
                      color: '#FFF'
                    }} size={'20px'} />} sx={{
                      backgroundColor: '#3898D9',
                      '&:hover': {
                        backgroundColor: '#3898D9'
                      }
                    }} onClick={e => {
                      if (preventUpload) {
                        uppy.reset();
                      } else {
                        actuallyUpload();
                      }
                    }}>
                          {preventUpload ? 'Reset' : 'Update Locations'}
                        </Button>
                      </Box>
                      <Box />
                    </Box>
                  </Stack>}
              </Paper>
            </Stack>
          </Box>
        </div>
      </div>
    </>;
};