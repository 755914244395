import { Typography } from '@mui/material';
import React from 'react';
export const ReadReviewMessage = props => {
  const message = props.data.message || '';
  return <>
      <div className="form-group">
        <Typography sx={{
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        pl: '20px'
      }}>{message}</Typography>
      </div>
    </>;
};